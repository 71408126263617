// Contact.js
import React from "react";
import ContactForm from "./ContactForm";
import Layout from "./Layout";

const Contact = () => {

  
  return (
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%", fontFamily: "CutiveMono"}}>
        <ContactForm />
      </div>
  );
};

export default Contact;
