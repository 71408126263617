import React from "react";
import Layout from "./Layout";
import WavingHandIcon from '@mui/icons-material/WavingHand';

const Home = () => {
    return (
      <Layout>
        <div class="container" >
          <div>
            <div class="side" style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontWeight: '800', fontFamily: "CutiveMono"}}>hi & welcome to my page   </h1>
              <WavingHandIcon style={{ marginRight: '10px', marginLeft: '10px', color: 'crimson', opacity:'56%'}} />
            </div>
            <div style={{fontFamily: "CutiveMono"}} ><p>I'm Isabella.</p> <p>I'm a third-year Computer Science student at the Georgia Institute of Technology.</p> <p>I have a focus in Artificial Intelligence & Human-Computer Interaction. </p></div>
            <div style={{fontFamily: "CutiveMono"}}><p>I'm passionate about building things 🔨, solving complex problems 🧠, and meeting new people 😃.</p></div>
          </div>
        </div>
      </Layout>
    );
};

export default Home;
