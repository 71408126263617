// Header.js
import React from "react";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const Header = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tab label="Isabella Martin" component={Link} to="/" style={{ fontFamily: 'CutiveMono', fontWeight: '950', color: 'crimson', fontSize: 'larger', opacity: '85%' }} />
      <div style={{ flex: '1' }}></div> {/* This div takes up the available space */}
      <Tabs value={false} variant="fullWidth">
        <Tab label="About" component={Link} to="/about" style={{ fontFamily: 'CutiveMono'}} />
        <Tab label="Projects" component={Link} to="/projects" style={{ fontFamily: 'CutiveMono'}} />
        <Tab label="Resume" component={Link} to="/resume" style={{ fontFamily: 'CutiveMono'}}/>
        <Tab label="Contact" component={Link} to="/contact" style={{ fontFamily: 'CutiveMono'}}/>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Tab icon={<LinkedInIcon />} component={Link} target="_blank" to="https://www.linkedin.com/in/isabella-a-martin" style={{ color: 'crimson', padding: '0', marginRight: '5px' }} />
          <Tab icon={<GitHubIcon />} component={Link} target="_blank" to="https://github.com/your-github-profile" style={{ color: 'crimson', padding: '0', marginLeft: '-65px' }} />
        </div>
      </Tabs>
    </div>
  );
};

export default Header;
