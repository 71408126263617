import React, { useState } from "react";
import axios from "axios"; 
import Layout from "./Layout";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const send_email_url = "http://localhost:3030/api/send-email";
    
    try {
      const response = await axios.post(send_email_url, {
        name: formData.name, 
        email: formData.email, 
        message: formData.message, 
      });
      if (response.status === 200) {
        // Email sent successfully, handle success or redirection here
      }
    } catch (error) {
      // Handle errors here
    }
  };

  return (
    <Layout>
      <div className="contact-form">
        <div>
          <h1>let's get in touch. </h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">name: </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
            <label htmlFor="name">email: </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
            <label htmlFor="name">message: </label>
              <input
                type="text"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <button type="submit" style={{ fontFamily: "CutiveMono"}}>submit</button>
          </form>
        </div>
      </div>  
    </Layout>
  );
};

export default ContactForm;
