import React from "react";
const layoutStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '90px', 
  marginLeft: '90px', 
  paddingTop: '40px'
};



const Resume = () => {
  return (
    <div style={layoutStyles}>
      <iframe
        title="Resume"
        src={process.env.PUBLIC_URL + "/IsabellaAMartin.pdf"}
        width="80%"
        height="600px"
      ></iframe>
    </div>
  );
};

export default Resume;
