import React from 'react';

const layoutStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '90px', 
  marginLeft: '90px', 
  paddingTop: '40px'
};

const Layout = ({ children }) => {
  return (
    <div style={layoutStyles}>
      {children}
    </div>
  );
};

export default Layout;
