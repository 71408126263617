import React, {useState} from "react"; 


const ProjectDetails = ({ subTitle, description }) => {
    return (
      <div>
        <h5>{subTitle}</h5>
        <p>{description}</p>
      </div>
    );
};

const Project = ({ title, projectDetails }) => {
    const [isProjectOpen, setIsProjectOpen] = useState(false); 

    const toggleProjectDetails = () => {
        setIsProjectOpen(!isProjectOpen); 
    }; 

    return(
        <div>
            <div onClick={toggleProjectDetails}>
                <h4 style={{ textDecoration: "underline", cursor: "pointer"}}>
                    {title}
                </h4>
            </div>

        {isProjectOpen && (
            <div>
                {projectDetails.map((detail, index) => (
                    <ProjectDetails
                        key={index}
                        subTitle={detail.subTitle}
                        description={detail.description}
                    />
                ))}     
            </div>
        )}
        </div>
    ); 
}; 

export default Project; 
