import React from "react";
import Layout from "./Layout";
import Project from "./Project";

const Projects = () => {


  const projectData = [
    {
      title: "Autobots: Object Recognition & NLP",
      projectDetails: [
        {
          subTitle: "Project Background",
          description: "I have been involved in an exciting project as part of my undergraduate research in the Georgia Tech Vertically Integrated Project (VIP) Team, Autobots.Over the course of two semesters, I focused my work on Object Recognition for Search and Rescue robots. For my third and final semester, I have shifted to focus on Natural Language Processing (NLP) for marsupial robots and task command. My project has a strong focus on enhancing the capabilities of search and rescue robots in demanding scenarios."
        },
        {
          subTitle: "Object Recognition for S&R",
          description: "My initial two semesters, I dedicated my research to advancing Object Recognition for Search and Rescue robots to identify and understand objects in challenging and often unpredictable environments. The ability to recognize objects is a critical component of autonomous robotics, especially for tasks in the realm of S&R operations. Beyond simple object recognition, I became more concentrated on facial recognition. The ability to recognize facial features in a search and rescue operation is crucial.I will attach some of my work below."
        },
        {
          subTitle: "Natural Language Processing for Marsupial Robots",
          description: "My current focus within this project is Natural Langauge Processing (NLP) for marsupial robots. The core challenge I am addressing revolves around task command and communication. To make search and rescue robots more versatile and adaptable, I am working on NLP techniques to facilitate integrated task commands. I have concentrated on using 'Robot Grammar' in my work. This involves mapping over 20 verbs and phrases to their equivalent command, enabling a robot to understand and execute these commands effectively. The goal is to createa a system where a search and rescue robot can interpret a command, such as 'put the green ball on the table', and take meaningful action based on that command. You should also be able to say 'Grab the green ball and place it on the table.', or 'Bring the green ball to the table.' These commands should result in the same behavior from the robot."
        },
        {
          subTitle: "Project Goals",
          description: "This work this project is significant, especially in emergency situations where human intervention is too risky or limited. Autonomous robots equipped with advanced NLP capabilities can be deployed to navigate complex environments, locate people, and execute critical tasks. In the event of a diaster where typical S&R efforts are hindered, this work would make a significant impact. Given the robot could understand and act upon natural language commands, becomes an invaluable asset. It can efficiently perform tasks like locating and moving objects, or even relaying critical information to rescue teams via Computer Vision. Ultimately, my goal by the end of this semester is to make advancements in the work of S&R robots. "
        }
      ]
    },
    {
      title: ".Breathe(): An Android Application",
      projectDetails: [
        {
          subTitle: "Project Background",
          description: "I am currently dedicated to developing an Android application that holds profound significance to me - a Breathing Simulator designed to aid individuals coping with anxiety and panic attacks. Drawing from my own experiences battling anxiety, I have begun this project to provide a valuable resource for others facing similar challenges. "
        }, 
        {
          subTitle: "App Functionality", 
          description: "The core functionality of the app revolves around three distinct breathing techniques: 4-4-4 box breathing, 4-7-8, and 7-11. Through engaging animations, the app guides users through these exercises, synchronizing inhalation and exhalation with the inflation and deflation of a shape on the screen. The visual and interactive approach effectively helps to lower heart rates and alleviate fight-or-flight symptoms."
        }, 
        {
          subTitle: "Future Enhancements & Goals",
          description: "Having personally witnessed the calming effects of controlled breathing, I am determined to share this tool with others who may benefit from it. In addition to the breathing exercises, my vision for the app includes features such as user accounts for progress tracking, a repository of mental health resources, and a streak feature to encourage daily practice.  By merging technological innovation with compassion, my ultimate goal is to positively impact the mental well-being of individuals and ultimately launch the app on major app stores."
        }
      ]
    }, 
    {
      title: "Campus Discovery App: An Android Application", 
      projectDetails: [
        {
          subtitle: "Project Background",
          description: "The Campus Discovery app was developed as part of my Object-Oriented Design course, where I worked together with three talented classmates. As the project leader, I took charge of organizing sprints, delegating tasks, and honing my leadership & teamwork skills. This project provided a unique opportunity to delve into Android App development, applying the design principles learned in class."
        },
        {
          subtitle: "Project Mission",
          description: "Our mission was clear: create an intuitive and visually appealing app to organize campus events for students and faculty. We prioritized user experience, ensuring our app was user-friendly and engaging. We meticuously followed object-oriented design principles, focusing on avoiding code smells and adhereing to Agile methodology."
        }, 
        {
          subtitle: "Project Features",
          description: "The application featured event listing, RSVP functionality, and an event map."
        }
      ]
    }
  ];


  return (
    <Layout>
      <div style={{
        marginTop: '20px', 
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        fontFamily: "CutiveMono"
        }}
      >
          <h2 style={{marginRight: '10px'}}>Current & Past Projects </h2>
        {projectData.map((project, index) => (
          <Project
            key={index}
            title={project.title}
            projectDetails={project.projectDetails}
          />
        ))}
      </div>
    </Layout>
  ); 
};

export default Projects;
