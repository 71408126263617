// About.js
import React from "react";
import img from '../picture.jpg'; // Adjust the path as needed

const aboutStyle = {
  fontFamily: "CutiveMono",
};

const imgStyle = {
  float: 'left', // Float the image to the left
  marginRight: '20px', // Adjust the margin to control the space between the image and text
  width: '20%',
  height: '20%'
};

const textOverlay = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: '240px', // Adjust the left position to match the width of the image
  padding: '20px', // Add padding to create space between the text and image
  zIndex: 1, // Ensure the text appears above the image
};

const About = () => {
  return (
    <div style={{ margin: "50px" }}>
      {/* <h2 style={{ fontFamily: "CutiveMono"}}>hi there! </h2> */}
      <div style={aboutStyle}>
        <img src={img} alt="Description of image" style={imgStyle} />
        <div>
        <p> Hi there! I'm Isabella, a third-year Computer Science student at the Georgia Institute of Technology🐝.</p>
          <p>
I'm on a journey of discovery through the world of technology, and I couldn't be more excited. From the moment I wrote my first line of code, I knew I was hooked.</p>
<p>What drives me?</p> <p> The never-ending puzzle of programming, the thrill of tackling complex challenges, and the boundless possibilities that technology offers.</p>
        <p> That's what led me to full-stack programming, machine learning, and AI 🚀.</p>
        <p>I thrive on learning and a drive to push the boundaries of what I can achieve. I wake up eager to explore new ideas and create technology that has the potential to make a real impact.</p>
        <p>But it's not just about the code; it's about people, too. I'm passionate about connecting with others and collaborating to bring ideas to life. I cherish those "aha" moments when we find elegant solutions to tricky problems together💡.</p>
<p>So, here I am, seeking opportunities to put my interpersonal, analytical, and coding skills to good use. If you have a challenge that needs conquering or a project that needs an extra dose of passion, I'm your enthusiastic collaborator.
</p>
<p>
Outside of tech, you'll find me out in nature with my two dogs, watching & rewatching all Christopher Nolan movies, or trying out a new recipe in the kitchen. I'm known for baking some pretty incredible chocolate croissants--life is about balance, after all:).</p>
<p>That is enough about me! Please feel free to reach out—I'm always up for a good conversation! I look forward to connecting with you. 🌟
</p>
</div>
      </div>
    </div>
  );
};

export default About;
// const About = () => {
//   return (
//         <div style={{margin: "50px", fontFamily: "CutiveMono"}}>
//             <div><h2>about me</h2></div>
//             <div><img src={img} alt="Description of image" style={aboutStyle} /></div>
//             <div><p>Hey there! I'm Isabella, a third-year Computer Science student at the Georgia Institute of Technology. 🐝
// I'm on a thrilling journey of discovery through the world of technology, and I couldn't be more excited about it. From the moment I wrote my first line of code, I knew I was hooked.
// What drives me? It's the never-ending puzzle of programming, the thrill of tackling complex challenges, and the boundless possibilities that technology offers. I'm all about that full-stack magic, the wonders of machine learning, and the awe-inspiring realm of AI. 🚀

// I thrive on learning and believe that curiosity is our greatest asset. Every day, I wake up eager to explore new horizons, dive into the latest trends, and create things that have the potential to make a real impact.
// But it's not just about the code; it's about people, too. I'm passionate about connecting with others and collaborating to bring ideas to life. I cherish those "aha" moments when we find elegant solutions to tricky problems together. 💡
// So, here I am, seeking opportunities to put my interpersonal, analytical, and coding skills to good use. If you have a challenge that needs conquering or a project that needs an extra dose of passion, I'm your enthusiastic collaborator.
// Outside of tech, you'll find me exploring the great outdoors, diving into a captivating book, or brewing up a storm in the kitchen. Life is about balance, after all!
// Let's connect, learn, and build something amazing together. Feel free to reach out—I'm always up for a good tech chat or a new adventure! 🌟
// </p></div>
//         </div>
//   ); 
// };

// export default About;
